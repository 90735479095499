import React from 'react';
import './App.css';

function App() {
  React.useEffect(()=>{
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectURL = urlParams.get('redirect_url');
    const authCode = 'auth_code=abc123';

    if(redirectURL)
      document.getElementById('submit').setAttribute('href',`${redirectURL}?${authCode}`);
  },[])

  return (
    <div className="app">
      <a className="submit" id="submit">
        Submit
      </a>
    </div>
  );
}

export default App;
